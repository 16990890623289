@use "@angular/material" as mat;


.mat-tooltip{
  font-size:15px;
}


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// @import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Admin-menu-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$Admin-menu-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$Admin-menu-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$Admin-menu-theme: mat.m2-define-light-theme(
  $Admin-menu-primary,
  $Admin-menu-accent, 
  $Admin-menu-warn
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($Admin-menu-theme);

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import "./common-styles.scss"; 
 

html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height:100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


mat-card {
  padding:10px;
}

mat-hint{
  font-size:14px;
  color: rgb(184, 184, 184);
}

button {
  font-size:14px;
}

// angular 15-ben ez a neve, de ez bármikor változhat
// ha nincs padding a dialógusban, akkor emiatt van
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 13px;
}

.mat-expansion-panel-body {
  padding: 0px !important;
}

.mat-expansion-panel{
  box-shadow: none !important;
}

.mat-snack-bar-container {
  margin-bottom: 80px !important;
}

.mat-snackbar-success {
  background-color: #205700;
  color: #cccccc;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

.mat-snackbar-fail {
  background-color: #690000;
  color: #cccccc;

  .mat-simple-snackbar-action {
    color: white !important;
  }
}

.mat-tooltip{
  font-size:15px;
}

// Section stylings

.section-title {
  font-size: 1.5em;
  font-weight: 500;
  margin: 10px 10px 10px 0;
}

.section-no-data-available {
  margin-top: 30px;
  text-align: center;
  font-size: 1.2em;
  font-style: italic;
  color: lightgrey;
}

.section-add-item {
  position: fixed;
  bottom: 80px;
  right: 50px;
}

.button-loading:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner-animation 0.8s linear infinite;
}

@keyframes spinner-animation {
  to {
    transform: rotate(360deg);
  }
}

.ngx-background-spinner{
  top:58px !important;
}

.rounded-dialog .mat-dialog-container {
  border-radius: 20px !important;
}

.no-padded-dialog .mat-dialog-container {
  padding: 0
}
